var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          'id',
          'name',
          Object.assign({}, _vm.getHelperObject('imputation_type'), {label: 'Type'}),
          Object.assign({}, _vm.getHelperObject('imputation_class', null, 'imputation_classes'), {label: 'Class'}), // TODO: plural
          'stage',
          Object.assign({}, _vm.helpers.validIcons, {key: 'is_metric'}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'interest'}),
          Object.assign({}, _vm.helpers.activeBadge, {key: 'active', helper: Object.assign({}, _vm.helpers.activeBadge.helper, {id: '_status'})}) // TODO: really ?
        ],"config":{
          url: 'admin/imputations',
          route: '/admin/property/imputations',
          name: 'imputation',
          params: { _lists: 'imputation_classes,imputation_types,imputation_class_types,' },
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            'id',
            'name',
            { ...getHelperObject('imputation_type'), label: 'Type' },
            { ...getHelperObject('imputation_class', null, 'imputation_classes'), label: 'Class' }, // TODO: plural
            'stage',
            { ...helpers.validIcons, key: 'is_metric' },
            { ...helpers.validIcons, key: 'interest' },
            { ...helpers.activeBadge, key: 'active', helper: { ...helpers.activeBadge.helper, id: '_status' } } // TODO: really ?
          ]"
          :config="{
            url: 'admin/imputations',
            route: '/admin/property/imputations',
            name: 'imputation',
            params: { _lists: 'imputation_classes,imputation_types,imputation_class_types,' },
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Imputations',
  mixins: [crudMixin],
  methods: {
    parseItems(parent, items) {
      return items.map(item => {
        item.stage = '--'
        const imputation_class = this.view_helpers.imputation_classes[item.imputation_class_id]
        if (imputation_class) {
          item.stage = (this.view_helpers.imputation_class_types[imputation_class.imputation_class_type_id] || {}).name || '--'
        }
        return item
      })
    }
  }
}
</script>
